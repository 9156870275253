import { useState, useEffect } from 'react'
import './index.css'

interface ArticleResponse {
    id: number;
    articleHash: string;
    articleTitle: string;
    articleDescription: string;
    articleCategory: string;
    articleImage: string;
    articleContent: string;
    articleUrl: string;
    bitlyLink: string;
    domain: string;
    redditTitle: string;
    redditFlairText: string;
    redditFlairTextAlt: string;
    redditScore: number;
    redditUpvoteCount: number;
    redditUpvoteRatio: number;
    redditCreatedAt: string;
    crossPostCount: number;
    over18: boolean;
    createdAt: string;
}

interface DrawerContentProps {
    articleId: number
}

export const DrawerContent: React.FC<DrawerContentProps> = ({ articleId }) => {
    const [article, setArticle] = useState<ArticleResponse | null>(null)

    useEffect(() => {
        fetch(`https://news.vuels.com/articles/${articleId}`)
            .then(res => res.json())
            .then((data: ArticleResponse) => setArticle(data))
    }, [articleId])

    return (
        <div className="p-4">
            {article === null ? (
                <span>Loading...</span>
            ) : (
                <>
                    <div className="text-2xl font-thin pb-4">{article.articleTitle}</div>
                    <div className="text-base DangerousHTML" dangerouslySetInnerHTML={{ __html: article.articleContent }}></div>
                    <a className="inline-block px-6 py-2 border-2 border-blue-600 text-blue-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-full text-center mb-4" href={article.bitlyLink}>
                        Go To Article
                    </a>
                </>
            )}
        </div>
    )
}
