export const getColor = (num: number, opacity = 1): string => {
    switch(num) {
        case 1: return `rgba(63, 81, 181, ${opacity})`
        case 2: return `rgba(76, 175, 80, ${opacity})`
        case 3: return `rgba(255, 87, 34, ${opacity})`
        case 4: return `rgba(33, 150, 243, ${opacity})`
        case 5: return `rgba(156, 39, 176, ${opacity})`
        case 6: return `rgba(103, 58, 183, ${opacity})`
        case 7: return `rgba(0, 150, 136, ${opacity})`
        case 8: return `rgba(96, 125, 139, ${opacity})`
        case 9: return `rgba(121, 85, 72, ${opacity})`
        case 10: return `rgba(233, 30, 99, ${opacity})`
        default: return `rgba(0, 0, 0, ${opacity})`
    }
}
