import { useContext } from 'react'
import { NewsContext } from '../../context'
import FlashCard from '../../components/FlashCard'

const Home = () => {
    const articles = useContext(NewsContext)
    const topScore = articles.articles.reduce((acc, val) => val.score > acc ? val.score : acc, 0)

    return (
        <div className="container">
            <ul className="container mx-auto w-full">
                {articles.articles.map(article => (
                    <li className="pb-4" key={article.order}>
                        <FlashCard key={article.bitly} topScore={topScore} {...article} />
                    </li>
                ))}
                {articles.loading === true ? (
                    <div className="flex flex-col justify-center items-center h-full pt-44">
                        <div className="spinner-border animate-spin inline-block w-8 h-8 rounded-full" role="status">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill='grey'><path d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"/></svg>
                        </div>
                        <div className="text-5xl pt-10 font-thin">Loading...</div>
                    </div>
                ): null}
                {articles.loading !== true && articles.articles.length === 0 ? (
                    <div className="flex flex-col justify-center items-center">
                        <div className="text-5xl pt-24 font-thin text-red-500">Failed To Load</div>
                    </div>
                ): null}
            </ul>
        </div>
    )
}

export default Home
