import React, { createContext, useState, useEffect } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

interface Article {
    id: number;
    order: number;
    title: string;
    url: string;
    bitly: string;
    image: string;
    domain: string;
    score: number;
    nsfw: boolean;
    category: string;
}
interface ArticleState {
    loading: boolean;
    articles: Article[];
}

const NewsContext = createContext<ArticleState>({ loading: true, articles: [] })
const NewsDispatchContext = createContext<() => void>(() => null)

const NewsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [articles, setArticles] = useState<ArticleState>({ loading: true, articles: [] })
    const setArticleRead = () => setArticles({ loading: true, articles: [] })

    useEffect(() => {
        const abortCtrl = new AbortController()
        const opts = { signal: abortCtrl.signal }
        let month = (dayjs().utc().get('month') + 1).toString()
        if (Number(month) < 10) month = '0' + month
        let day = dayjs().utc().get('date').toString()
        if (Number(day) < 10) day = '0' + day
        const date = `${dayjs().utc().get('year')}-${month}-${day}`

        fetch(`https://news.vuels.com/articles/${date}/${dayjs().hour()}`, opts)
            .then((response) => response.json())
            .then((data) => setArticles({ loading: false, articles: data }))
            .catch((error) => {
                setArticles({ loading: false, articles: [] })
                console.log(error.message)
            })

        return () => abortCtrl.abort()
    }, [])

    return (
        <NewsContext.Provider value={articles}>
            <NewsDispatchContext.Provider value={setArticleRead}>
                {children}
            </NewsDispatchContext.Provider>
        </NewsContext.Provider>
    )
}

export { NewsProvider, NewsContext, NewsDispatchContext }
