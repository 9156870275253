import React, { useState, useCallback } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Drawer from 'react-bottom-drawer'
import { getColor } from '../../utils'
import { defaultImage } from './util'
import { DrawerContent } from '../DrawerContent'
import './index.css'

dayjs.extend(utc)

interface FlashCardProps {
    id: number;
    title: string;
    image: string;
    bitly: string;
    domain: string;
    score: number;
    nsfw: boolean;
    category: string;
    order: number;
    topScore: number;
}

const partOfDay = () => {
    const hours = dayjs().hour()
    return hours < 12 ? 'Morning' : hours < 18 ? 'Afternoon' : 'Evening'
}

const FlashSplash: React.FC<{ image: string }> = ({
    image
}) => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    console.log(image)

    return (
        <div className={`FlashSplash container w-full h-64 bg-cover mb-4`} style={{ backgroundImage: `url(${defaultImage})` }}>
            <div className={'FlashSplash-image w-full h-full bg-cover'} style={{ backgroundImage: `url('${image}')` }}></div>
            <div className={'FlashSplash-header p-4 text-4xl'}>
                <div className={'text-white font-bold'}>{monthNames[dayjs().month()]} {dayjs().date()}</div>
                <div className={'text-white text-xl'}>{days[dayjs().day()]} {partOfDay()}</div>
            </div>
            <div className={'FlashSplash-linear-gradient'}></div>
        </div>
    )
}

const FlashCard: React.FC<FlashCardProps> = ({
    id,
    title,
    category,
    order,
    image,
    bitly,
    domain,
    score,
    nsfw,
    topScore
}) => {
    const [drawerVisible, setDrawerVisible] = useState(false)
    const drawerToggle = useCallback(() => setDrawerVisible(false), [])

    const cleanTitle = title.split('|')[0].split(' - ')[0]

    return (
        <div className="container">
            {order === 1 ? (<FlashSplash image={image} />) : null}
            <div 
                className={`flex p-2 pb-5 border-slate-100 border-b ${order === 1 ? 'FlashSplash-first' : ''}`}
                onClick={() => setDrawerVisible(true)}>
                <div className="flex-none ml-2 mt-1">
                    <svg 
                        className="h-6 w-6 flex-none stroke-1" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                        style={{ stroke: getColor(order), fill: 'white' }}
                    >
                        <circle cx="12" cy="12" r="11" />
                        <text strokeWidth="0.4" x="50%" y="55%" dominantBaseline="middle" textAnchor="middle" fill="black">{order}</text>
                    </svg>
                </div>
                <div className="ml-4 pr-4">
                    <h3 className="text-sm font-bold" style={{ color: getColor(order) }}>{category.trim().replace(/^\w/, (c) => c.toUpperCase())}</h3>
                    <h2 className="text-2xl font-thin">{cleanTitle}</h2>
                    <h4 className="py-1 text-sm text-slate-400">{domain}</h4>
                    <div className="flex flex-wrap space-x-2 pt-4">
                        <svg fill={getColor(order)} className="w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M64 400C64 408.8 71.16 416 80 416H480C497.7 416 512 430.3 512 448C512 465.7 497.7 480 480 480H80C35.82 480 0 444.2 0 400V64C0 46.33 14.33 32 32 32C49.67 32 64 46.33 64 64V400zM342.6 278.6C330.1 291.1 309.9 291.1 297.4 278.6L240 221.3L150.6 310.6C138.1 323.1 117.9 323.1 105.4 310.6C92.88 298.1 92.88 277.9 105.4 265.4L217.4 153.4C229.9 140.9 250.1 140.9 262.6 153.4L320 210.7L425.4 105.4C437.9 92.88 458.1 92.88 470.6 105.4C483.1 117.9 483.1 138.1 470.6 150.6L342.6 278.6z"/></svg>
                        <span
                            className="px-4 py-0.5 rounded-full text-gray-500 bg-gray-200 font-semibold text-sm flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease">
                            {Math.floor((100 / topScore) * score)} % 
                        </span>
                    </div>
                    {nsfw ? <div>NSFW</div> : null}
                </div>
            </div>

            <Drawer 
                isVisible={drawerVisible} 
                onClose={drawerToggle}
                mountOnEnter={true}
                unmountOnExit={true}>
                <DrawerContent articleId={id} />
            </Drawer>
        </div>
    )
}

export default FlashCard
